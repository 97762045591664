/* Custom styles for range inputs */
input[type="range"] {
    -webkit-appearance: none; /* Override default styling */
    width: 100%;
    height: 8px; /* Height of the track */
    background: #e0e0e0; /* Track color */
    border-radius: 5px; /* Rounded corners */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Slightly transparent */
    transition: opacity .2s; /* Transition effect */
  }
  
  input[type="range"]:hover {
    opacity: 1; /* Fully opaque on hover */
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default styling */
    appearance: none; /* Override default styling */
    width: 20px; /* Width of the thumb */
    height: 20px; /* Height of the thumb */
    border-radius: 50%; /* Round thumb */
    background: #007bff; /* Thumb color */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 20px; /* Width of the thumb */
    height: 20px; /* Height of the thumb */
    border-radius: 50%; /* Round thumb */
    background: #007bff; /* Thumb color */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  input[type="range"]::-ms-thumb {
    width: 20px; /* Width of the thumb */
    height: 20px; /* Height of the thumb */
    border-radius: 50%; /* Round thumb */
    background: #007bff; /* Thumb color */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    background: #e0e0e0; /* Track color */
    border-radius: 5px; /* Rounded corners */
  }
  
  input[type="range"]::-moz-range-track {
    background: #e0e0e0; /* Track color */
    border-radius: 5px; /* Rounded corners */
  }
  
  input[type="range"]::-ms-track {
    background: #e0e0e0; /* Track color */
    border-radius: 5px; /* Rounded corners */
    height: 8px; /* Height of the track */
    border: none; /* Remove border */
  }
  html.lenis {
    height: auto;
  }
  
  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }
  
  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
  
  .lenis.lenis-stopped {
    overflow: hidden;
  }
  
  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }